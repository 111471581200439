































































import { computed, ref, defineComponent } from '@vue/composition-api';
import { NAV_ITEMS } from '@/constants/manage-program';

export default defineComponent({
  name: 'Nav',
  props: {
    startDate: {
      type: String,
      default: 'DAY, MON 1'
    },
    endDate: {
      type: String,
      default: 'DAY, MON 1'
    },
    value: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const activeTab = ref(NAV_ITEMS[0]);

    const compId = computed({
      get: () => props.value,
      set: newVal => {
        emit('input', newVal);
      }
    });
    function setActive(item) {
      activeTab.value = item;
    }

    return {
      items: ref(NAV_ITEMS),
      activeTab,
      compId,
      setActive
    };
  }
});
